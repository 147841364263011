<template>
    <div class="iframe-full">
      <a-button style="float: right" @click="$router.push({ path: '/employeehandbook/feedback' })">Tips og Varsling</a-button>
      <div>
        <div class="card-content" v-html="page.content.rendered"></div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      // Wordpress Posts Endpoint
      pageUrl: 'https://skygruppen.no/wp-json/wp/v2/pages/320',
      queryOptions: {
        password: 'SkyGruppenAS2021!!!',
        _embed: true, // Response should include embedded resources.
      },
      // Returned Posts in an Array
      page: {},
    }
  },
  methods: {
    // Get Recent Posts From WordPress Site
    getRecentMessages() {
      axios
        .get(this.pageUrl, { params: this.queryOptions })
        .then(response => {
          this.page = response.data
          console.log('Page retrieved!')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  mounted() {
    this.getRecentMessages()
  },
}
</script>

<style>
  .iframe-full iframe{
    width: 100%;
    height: calc(100vh - 85px);
  }

  .cui__utils__content{
    padding: 1em;
  }
</style>
